import "@babel/polyfill"
import "formdata-polyfill"
import smoothscroll from "smoothscroll-polyfill"
import Rellax from "rellax"
import "/components/navbar/navbar.js"
import "/components/modals/modals.js"
import "/components/indicators/indicator.js"
import "/components/contacts-form/contacts-form.js"

smoothscroll.polyfill()
var rellax = new Rellax(".rellax", {
  speed: -3,
  center: false,
  wrapper: null,
  round: true,
  vertical: true,
  horizontal: false,
})